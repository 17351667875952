<template>
        <div>
            <div class="show-ul">
                <div class="show-li">重要信息</div>
            </div>
            <div style="padding: 30px;">
                <div style="display: flex;padding: 25px;justify-content: space-between;width: 20%;border: solid 1px lightgrey;border-radius: 5px">
                    <div style="display:flex;flex-direction: column;">
                        <div>剩余积分<span>700</span></div>
                        <div>已兑换<span>300</span></div>
                    </div>
                    <div>积分记录</div>
                </div>
            </div>

            <div class="show-ul">
                <div class="show-li">问卷调查</div>
            </div>
            <div style="padding: 30px;width: 50%">
                <a-table :columns="columns" :data-source="data" bordered :pagination="false">
                </a-table>
            </div>

            <div class="show-ul">
                <div class="show-li">图书借阅合同</div>
            </div>
            <div style="padding: 30px;width: 50%">

                <a-table :columns="columns" :data-source="data" bordered :pagination="false">
                </a-table>

            </div>
            <div class="show-ul">
                <div class="show-li">满意度调查</div>
            </div>
            <div style="padding: 30px;width: 50%">
                <a-table :columns="columns" :data-source="data" bordered :pagination="false">
                </a-table>
            </div>
        </div>
</template>

<script>
    const columns = [
        {
            title: '问卷名称',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: '填写时间',
            dataIndex: 'address',
            key: 'address',
        },
    ];

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer'],
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser'],
        },
        {
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
            tags: ['cool', 'teacher'],
        },
    ];

    export default {
        data() {
            return {
                mode: 'top',
                data,
                columns,
            };
        },

        methods: {

        },
    }
</script>

<style scoped>
    .show-ul{
        padding: 30px 30px 10px 30px;
    }
    .show-li{
        border-width: 0px 0px 0px 5px;
        border-style: solid;
        border-color: #00a1ff;
        border-image: initial;
        padding: 0px 0px 0px 5px;
    }
</style>