<template>
    <div>
        <a-spin :tip="tip" :spinning="show" :delay="delayTime">
        <div class="c-title">
            <div>
                <a-radio-group v-model="selectTab">
                    <a-radio-button :value="index" v-for="(item,index) in tabs" @click="onChangeTab(index)">
                        {{item}}
                    </a-radio-button>
                </a-radio-group>
            </div>
            <div class="title-li">
                <a-button type="primary" style="margin-left: 10px">
                    购买产品
                </a-button>
            </div>
        </div>
        <div style="padding: 20px 60px 60px 60px">
            <div class="test">
                <a-table :columns="columns" :data-source="data" bordered :pagination="false">
<!--                    <template slot="operation" slot-scope="text, record">这里的text 表示text类型-->
                    <template slot="operation" slot-scope="text, record">
                      <div class="edit-item" @click="onRefund(record)"><span>退费</span></div>
                    </template>
                </a-table>
            </div>
        </div>
        <FormModel :config="modelConfig" :submit="onsubmit"></FormModel>
        </a-spin>
    </div>
</template>

<script>
    import FormModel from "../../components/Customer/FormModel";




    export default {
        components: {
            FormModel
        },
        props:{

        },
        data() {
            return {
                tabs:['线下订单','线上订单','积分兑换'],
                selectTab:0,
                tip:'加载中...',
                show:false,
                data:[
                    {
                        key: '1',
                        name: 'John Brown',
                        age: 32,
                        address: 'New York',
                        tags: ['nice', 'developer'],
                    },
                    {
                        key: '2',
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No.',
                        tags: ['loser'],
                    },
                    {
                        key: '3',
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney ',
                        tags: ['cool', 'teacher'],
                    },
                    {
                        key: '3',
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney ',
                        tags: ['cool', 'teacher'],
                    },
                    {
                        key: '3',
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney ',
                        tags: ['cool', 'teacher'],
                    },
                ],
                columns: [
                    {
                        title: '订单凭证号',
                        dataIndex: 'age',
                        key: 'age',
                        align:'center'
                    },
                    {
                        title: '产品名称',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '产品价格',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '数量',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '折扣金额',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '支付金额',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '支付方式',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '出单时间',
                        dataIndex: 'address',
                        key: 'address',
                        align:'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        key: 'operation',
                        align:'center',
                        scopedSlots: { customRender: 'operation' },//加这项表示 该列为template 插槽
                    },
                ],
                modelConfig: {
                    title:'退费',
                    visible:false,//弹窗
                    method:'',//提交方法
                    form:[
                        {type:'select',model:'select',placeholder:'请选择',label:'退费方式',value:'',list:[
                                {label:'整单退费',value:'1'},
                                {label:'部分退费',value:'2'},
                            ]},
                    ],
                }
            };
        },
        mounted() {
            let that =this;
            that.onChangeTab(0)
        },
        methods: {
            //切换Tab
            onChangeTab(index){
                let that = this;
                switch (index) {
                    case 0:
                        that.columns = [
                            {
                                title: '订单凭证号',
                                dataIndex: 'age',
                                key: 'age',
                                align:'center'
                            },
                            {
                                title: '产品名称',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '产品价格',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '数量',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '折扣金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '支付金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '支付方式',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '出单时间',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '操作',
                                dataIndex: 'operation',
                                key: 'operation',
                                align:'center',
                                scopedSlots: { customRender: 'operation' },//加这项表示 该列为template 插槽
                            },
                        ]
                        break;
                    case 1:
                        that.columns=[
                            {
                                title: '管易单号',
                                dataIndex: 'age',
                                key: 'age',
                                align:'center'
                            },
                            {
                                title: '平台单号',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '产品名称',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '支付时间',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '单价',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '数量',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '产品金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '折扣金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '折后金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '支付金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '店铺',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '购买ID',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '收货人',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '收货人手机号',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '收货人地址',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                        ];
                        break;
                    case 2:
                        that.columns=[
                            {
                                title: '兑换礼品',
                                dataIndex: 'age',
                                key: 'age',
                                align:'center'
                            },
                            {
                                title: '兑换积分',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '兑换时间',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '金额',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '支付流水号',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '联系人',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '联系电话',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '地址',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '快递单号',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '快递填写人',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                            {
                                title: '快递时间',
                                dataIndex: 'address',
                                key: 'address',
                                align:'center'
                            },
                        ];
                        break;
                }
            },
            onRefund(record){
                let that =this;
                // that.show = true
                console.log(record)
            },
            onsubmit(param){
                console.log(param)
            }
        },
    }
</script>

<style scoped>
    .edit-item{
        text-align: center;
    }
    .edit-item > span {
        color: #2473d5;
        cursor: pointer;
        font-weight: bold;

    }
    /deep/.ant-table-thead > tr > th{
        background-color: #ededed;
        border-right: 1px solid #dfdfdf;
    }
    .c-title{
        padding: 0px 60px 0px 60px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .title-li{
        display: flex;

    }
    .title-li>div{
        padding-left: 50px;
    }
</style>