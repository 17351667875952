<template>
        <div>
            <a-collapse default-active-key="1" :bordered="false">
                <a-collapse-panel key="1" header="基本信息" >
                    <div style="display: flex;flex-direction: column;padding: 20px;">
                        <div style="display: flex;flex-direction: column;width: 50%;">
                            <div class="div-ul">
                                <div class="div-ul-li">
                                    <label class="div-ul-li-name">姓名</label><div>张三</div>
                                </div>
                                <div class="div-ul-li">
                                    <label class="div-ul-li-name">性别</label><div>男</div>
                                </div>
                            </div>
                            <div class="div-ul">
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">所属中心</label><div>深圳百花）</div>
                                </div>
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">昵称</label><div>点击填写</div>
                                </div>
                            </div>
                            <div class="div-ul">
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">出生日期</label><div>2017-06-15</div>
                                </div>
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">年龄浮动</label><div>点击填写</div>
                                </div>
                            </div>
                            <div class="div-ul">
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">登录手机</label><div>点击填写</div>
                                </div>
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">客户类型</label><div>点击填写</div>
                                </div>
                            </div>
                            <div class="div-ul">
                                <div  class="div-ul-li">
                                    <label class="div-ul-li-name">备注</label><div>备注内容</div>
                                </div>
                            </div>
                        </div>
                        <div class="div-text" style="flex-direction: column;">
                            <label>多子女</label>
                            <div style="display: flex;align-items: center;margin-top:20px;justify-content: space-around;width: 13%">
                                <a-avatar :size="64" icon="user" />
                                <a-avatar :size="64" icon="user" />
                                <a-icon type="plus-circle"   :style="{ fontSize: '40px' }"/>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
            <a-collapse default-active-key="1" :bordered="false">
                <a-collapse-panel key="1" header="联系信息" :disabled="false">
                    <div style="display: flex;align-items: center;">
                        <div style="border: 1px solid rgb(232, 232, 232);background-color: revert;
    border-radius: 5px;display: flex;padding: 20px;max-width: 300px;justify-content: space-around;margin: 10px">
                            <div>妈妈</div>
                            <div>
                                <div>李莉莉</div>
                                <div>13258844458</div>
                            </div>
                            <div>主联系人</div>
                        </div>
                        <div style="border: 1px solid rgb(232, 232, 232);background-color: revert;
    border-radius: 5px;display: flex;padding: 20px;max-width: 300px;justify-content: space-around;margin: 10px">
                            <div>妈妈</div>
                            <div>
                                <div>李莉莉</div>
                                <div>13258844458</div>
                            </div>
                            <div>主联系人</div>
                        </div>
                        <div style="border: 1px solid rgb(232, 232, 232);background-color: revert;
    border-radius: 5px;display: flex;padding: 20px;max-width: 300px;justify-content: space-around;margin: 10px">
                            <div>妈妈</div>
                            <div>
                                <div>李莉莉</div>
                                <div>13258844458</div>
                            </div>
                            <div>主联系人</div>
                        </div>
                        <div @click="addContacts">
                            <a-icon type="plus" />添加联系人
                        </div>
                    </div>
                    <div style="display: flex;flex-direction: column;width: 50%;margin-top: 20px;margin-left: 10px">
                        <div class="div-ul">
                            <div class="div-ul-li">
                                <label class="div-ul-li-name">微信号码</label><div>1325887788</div>
                            </div>
                            <div class="div-ul-li">
                                <label class="div-ul-li-name">家庭电话</label><div>1325887788</div>
                            </div>
                        </div>
                        <div class="div-ul">
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">家庭地址</label><div>广东 深圳 南山 高新区00097</div><label style="margin-left: 10px;" @click="editAddress">编辑</label>
                            </div>
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">微信号码</label><div>1325887788</div>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
            <a-collapse default-active-key="1" :bordered="false">
                <a-collapse-panel key="1" header="其他信息">
                    <div style="display: flex;flex-direction: column;width: 50%;margin-top: 20px;margin-left: 10px">
                        <div class="div-ul">
                            <div class="div-ul-li">
                                <label class="div-ul-li-name">首次联络方式</label><div>点击填写</div>
                            </div>
                            <div class="div-ul-li">
                                <label class="div-ul-li-name">品牌认识途径</label><div>广告搜索</div>
                            </div>
                        </div>
                        <div class="div-ul">
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">创建时间</label><div>2022-11-15（刑玉柱）</div>
                            </div>
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">员工姓名</label><div>张三</div>
                            </div>
                        </div>
                        <div class="div-ul">
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">渠道来源</label><div>市场地推活动</div>
                            </div>
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">指定活动</label><div>2019中秋推广活动</div>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
            <a-collapse default-active-key="1" :bordered="false">
                <a-collapse-panel key="1" header="转介绍情况">
                    <div style="display: flex;flex-direction: column;width: 50%;margin-top: 20px;margin-left: 10px">
                        <div class="div-ul">
                            <div class="div-ul-li">
                                <label class="div-ul-li-name">ta转介绍了</label><div>5人</div>
                            </div>
                            <div class="div-ul-li">
                                <label class="div-ul-li-name">成交金额</label><div>广告搜索</div>
                            </div>
                        </div>
                        <div class="div-ul">
                            <div  class="div-ul-li">
                                <label class="div-ul-li-name">ta的介绍人</label><div>梅梅</div>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
            <FormModel :config="modelConfig" :submit="onsubmit"></FormModel>
        </div>
</template>

<script>
    import FormModel from "../../components/Customer/FormModel";
export default {
    components: {
        FormModel
    },
    props:{

    },
    data() {
        return {
            text: 'A dog is a type of domesticated animal. Known for its loyalty an',
            tip:'加载中...',
            show:false,
            delayTime:100,
            formItemLayout: {
                divCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            },
            config: {
                rules: [{ type: 'object', required: true, message: 'Please select time!' }],
            },
            modelConfig:{
                visible:false,
                title:'新建任务',
                method:'',
                form:[
                    {type:'date',model:'date',placeholder:'请选择',label:'时间',value:'2022-11-11'},
                    {type:'input',model:'taskName',placeholder:'请选择',label:'任务名称',value:''},
                    {type:'select',model:'select',placeholder:'请选择',label:'动态类型',value:'',list:[
                            {label:'全部',value:'1'},
                            {label:'我关注的',value:'2'},
                        ]},
                    {type:'select',model:'selects',placeholder:'请选择',label:'客户',value:'',list:[
                            {label:'全部',value:'1'},
                            {label:'我关注的',value:'2'},
                        ]},
                ]

            },
        };
    },

    methods: {
        submit(){
            console.log(this.form)
            let that =this;
            that.show = true
            const axios = require('axios');
            axios.get('https://customer.com/api/student')
                .then(function (response) {
                    // 处理成功情况
                    if(response.data.code==200){
                        that.$message.success('新增成功');
                        that.show=false;
                    }
                })
                .catch(function (error) {
                    // 处理错误情况
                    console.log(error);
                })
                .then(function () {
                    // 总是会执行
                });

        },
        handleChange(){

        },
        handleSubmit(){

        },
        addContacts(){
            let that =this;
            that.modelConfig.visible=true
            that.modelConfig.title = '联系方式';
            that.modelConfig.method = 'onSubmitAddContacts';
            that.modelConfig.form=[
                {type:'select',model:'select',placeholder:'请选择',label:'关系',value:'',list:[
                        {label:'爸爸',value:'1'},
                        {label:'妈妈',value:'2'},
                    ]},
                {type:'input',model:'taskName',placeholder:'请选择',label:'姓名',value:''},
                {type:'input',model:'taskName',placeholder:'请选择',label:'电话',value:''},
                {type:'input',model:'taskName',placeholder:'请选择',label:'邮箱',value:''},
                {type:'input',model:'taskName',placeholder:'请选择',label:'公司',value:''},
            ]
        },
        onsubmit(config){
            console.log('表单参数'+config.form)
            console.log('执行方法：'+config.method)
            return true;//回调参数回去
        },
        editAddress(){
            let that =this;
            that.modelConfig.visible=true
            that.modelConfig.title = '编辑地址';
            that.modelConfig.method = 'onSubmitAddress';
            that.modelConfig.form=[
                {type:'select',model:'select',placeholder:'请选择',label:'省份',value:'',
                    list:[
                        {label:'广西',value:'1'},
                        {label:'广东',value:'2'},
                    ]
                },
                {type:'select',model:'select',placeholder:'请选择',label:'市',value:'',
                    list:[
                        {label:'广西',value:'1'},
                        {label:'广东',value:'2'},
                    ]
                },
                {type:'select',model:'select',placeholder:'请选择',label:'区',value:'',
                    list:[
                        {label:'广西',value:'1'},
                        {label:'广东',value:'2'},
                    ]
                },
                {type:'textarea',model:'taskName',placeholder:'请选择',label:'详细地址',value:''},
            ]
        },
        onSubmitAddress(){
            console.log('diaoyon')
        }

    },
}
</script>

<style scoped>
    .div-ul{
        display: flex;justify-content: space-between;padding: 10px
    }
    .div-ul-li{
        display: flex;
        flex-basis: 50%;
    }
    .div-ul-li-name{
        padding-right: 50px;
        min-width: 106px;
    }
    /deep/.ant-form-item-children{
        display: flex ;
    }
    .div-text{
        display: flex;justify-content: space-between;padding: 10px;
    }
    .div-li{
        flex-basis: 20%;display: flex;justify-content: space-around;
    }
</style>