<template>
        <div>
            <div class="c-header">
                <a-button type="primary" style="margin-left: 10px">
                    体验记录
                </a-button>
                <a-button type="primary" style="margin-left: 10px">
                    OMO体验课
                </a-button>

                <a-button type="primary" >
                    适合ta的课程
                </a-button>
            </div>
            <div class="c-title">
                <div>
                    <a-radio-group v-model="mode">
                        <a-radio-button value="top">
                            按时间显示
                        </a-radio-button>
                        <a-radio-button value="left">
                            按课程显示
                        </a-radio-button>
                    </a-radio-group>
                </div>
                <div class="title-li">
                    <div><a-icon type="tablet" theme="filled" :style="{ color: 'rgb(202 205 202)' }"/>未上课</div>
                    <div><a-icon type="tablet" theme="filled" :style="{ color: 'rgb(16 195 30)' }"/>到课</div>
                    <div><a-icon type="tablet" theme="filled" :style="{ color: 'rgb(231 172 38)' }"/>请假</div>
                    <div><a-icon type="tablet" theme="filled" :style="{ color: 'rgb(205 69 39)' }"/>缺课</div>
                </div>
            </div>
            <div style="padding: 20px 60px 60px 60px">
                <div class="c-content">
                    <div class="content-ul">
                        <div class="li-first">
                            <div class="first-title">基础能力课</div>
                            <div>课次：40</div>
                        </div>
                        <div class="li-right">
                            <div class="ul-c" v-for="item in 20">
                                <div class="ul-content">
                                    <div class="c-class-title">
                                        <div>班级名称</div>
                                        <div>班级名称</div>
                                    </div>
                                    <div class="c-class-li">
                                        <div><a-icon type="clock-circle" theme="filled" :style="{ color: '#8b8b8b' }"/>12-09 周五 15:00</div>
                                        <div><a-icon type="wechat" theme="filled" :style="{ color: '#8b8b8b' }"/> 李想</div>
                                        <div><a-icon type="environment" theme="filled" :style="{ color: '#8b8b8b' }"/> 教室1</div>
                                        <div><a-icon type="youtube" theme="filled" :style="{ color: '#8b8b8b' }"/>基础能力</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-ul">
                        <div class="li-first">
                            <div class="first-title">记忆力专项课</div>
                            <div>课次：40</div>
                        </div>
                        <div class="li-right">
                            <div class="ul-c" v-for="item in 7">
                                <div class="ul-content">
                                    <div class="c-class-title">
                                        <div>班级名称</div>
                                        <div>班级名称</div>
                                    </div>
                                    <div class="c-class-li">
                                        <div><a-icon type="clock-circle" theme="filled" :style="{ color: '#8b8b8b' }"/>12-09 周五 15:00</div>
                                        <div><a-icon type="wechat" theme="filled" :style="{ color: '#8b8b8b' }"/> 李想</div>
                                        <div><a-icon type="environment" theme="filled" :style="{ color: '#8b8b8b' }"/> 教室1</div>
                                        <div><a-icon type="youtube" theme="filled" :style="{ color: '#8b8b8b' }"/>基础能力</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-ul">
                        <div class="li-first">
                            <div class="first-title">音感课</div>
                            <div>课次：40</div>
                        </div>
                        <div class="li-right">
                            <div class="ul-c" v-for="item in 7">
                                <div class="ul-content">
                                    <div class="c-class-title">
                                        <div>班级名称</div>
                                        <div>班级名称</div>
                                    </div>
                                    <div class="c-class-li">
                                        <div><a-icon type="clock-circle" theme="filled" :style="{ color: '#8b8b8b' }"/>12-09 周五 15:00</div>
                                        <div><a-icon type="wechat" theme="filled" :style="{ color: '#8b8b8b' }"/> 李想</div>
                                        <div><a-icon type="environment" theme="filled" :style="{ color: '#8b8b8b' }"/> 教室1</div>
                                        <div><a-icon type="youtube" theme="filled" :style="{ color: '#8b8b8b' }"/>基础能力</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    components: {
    },
    props:{

    },
    data() {
        return {
            mode: 'top',
        };
    },

    methods: {

    },
}
</script>

<style scoped>
    .c-class-li{
        display: flex;
        flex-direction: column;
        padding: 10px;

    }

    .c-class-title{
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .first-title{
        font-size: 20px;
        font-weight: bold;
    }
    .ul-c{
        width: 14.2863%;
        display: flex;
    }
    .li-first{
        flex-basis: 10%;
        border: solid 1px lightgrey;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-width: 0px 1px 0px 0px;
    }
    .li-right{
        margin-bottom: 20px;
        padding: 7px;
        display: flex;
        flex-wrap: wrap;
        flex-basis: 90%;
    }
    .content-ul{
        display: flex;
        border: solid 1px lightgrey;

    }
    .ul-content{
        display: flex;
        flex-direction: column;
        margin: 5px;
        border: solid 1px lightgrey;
        width: 100%;
        border-left-width: 5px;
        border-left-color: red;
    }
    .c-content{
        display: flex;
        flex-direction: column;
    }
    .c-header{
        display: flex;
        flex-direction: row-reverse;
        padding: 40px 60px 40px 0px;
    }
    .c-title{
        padding: 0px 60px 0px 60px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .title-li{
        display: flex;

    }
    .title-li>div{
        padding-left: 50px;
    }
</style>