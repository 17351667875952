<template>
	<div class="contract-detail-page">
        <div class="student-profile-row content-item-row">
            <div class="vertical-line title-row">学员信息 <span><a-icon type="arrow-up"/></span></div>

            <div class="content-row student-profile-content">
                <a-row>
                    <a-col :span="3">
                        <a-avatar :size="64" icon="user"/>
                    </a-col>
                    <a-col :span="10">
                        <div><span>汪乐</span><span>乐乐</span><span><a-icon type="man"/></span></div>
                        <div><span>生日 2018-10-24 </span><span>（2岁6月）</span></div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="3"></a-col>
                    <a-col :span="21">
                        <a-row><span>监护人</span><span>李莉莉（妈妈）  |   13685654526  |  85658545@qq.com</span></a-row>
                        <a-row><span>地址 </span><span> 深圳市宝安区宝安中心花年年郡6栋603号</span></a-row>
                    </a-col>
                </a-row>
            </div>
        </div>
        <div class="content-item-row">
            <div class="vertical-line title-row">课程信息 <span><a-icon type="arrow-up" /></span></div>
            <a-row class="content-row course-content">
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">收费类型</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">按课次收费</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">收费标准</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">基础能力98课时包</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">基本课时</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">98节</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">赠送课时</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">28节</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">有效期限</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">36个月</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">赠送时长</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">3个月</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">开始时间</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">2020-10-10</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">结束时间</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">2021-10-10</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col"  :span="3">上课限制</a-col>
                    <a-col class="info-item-value info-item-col"  :span="8">每周2节课，1节直播课、1节线下课</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">可上课程</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">基础能力课、音感课、活动课、亲子课</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">备注</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">赠送256元的拉杆箱，右脑游戏纸牌99元</a-col>
                </a-row>
            </a-row>
        </div>
        <div class="content-item-row">
            <div class="vertical-line title-row">付费信息 <span><a-icon type="arrow-up" /></span></div>
            <a-row class="content-row payment-info-content">
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">总金额</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">2564654.00</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">优惠金额</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">2500.00 <span><a-icon type="account-book" ></a-icon></span></a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">应收金额</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">37855.00</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">已收金额</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">37800.00<span style="background-color:  #28bc6e;color: white">全额交费</span><span style="background-color: #fd6566;color: white">待补交</span></a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col :span="3">备注</a-col>
                    <a-col :span="8">赠送256元的拉杆箱，右脑游戏纸牌99元</a-col>
                </a-row>

            </a-row>
        </div>
        <div class="content-item-row">
            <div class="vertical-line title-row">签单信息 <span><a-icon type="arrow-up" /></span></div>
            <a-row class="content-row sign-info-content">
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">签单人</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">王丽</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">分单人</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">李爱聊  <span style="color: #108ee9" @click="commissionInfoShow">分单详情</span></a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">所属中心</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">深圳百花</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">发票</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">未开</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">创建时间</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">2021-10-10</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">最后修改时间</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">2021-10-11</a-col>
                </a-row>
            </a-row>
        </div>
        <div class="content-item-row">
            <div class="vertical-line title-row">使用情况 <span><a-icon type="arrow-up" /></span></div>
            <a-row class="content-row used-detail-content">
                <a-row class=" course-info-item">
                    <a-col class="info-item-label info-item-col" :span="3">普通课时</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">20/98</a-col>
                    <a-col class="info-item-label info-item-col" :span="3">赠送课时</a-col>
                    <a-col class="info-item-value info-item-col" :span="8">0/28</a-col>
                </a-row>
                <a-row class=" course-info-item">
                    <a-col  class="info-item-label info-item-col" :span="3">课耗金额</a-col>
                    <a-col  class="info-item-value info-item-col" :span="8">10000.00</a-col>
                    <a-col  class="info-item-label info-item-col" :span="3">剩余金额</a-col>
                    <a-col  class="info-item-value info-item-col" :span="8">27800.00</a-col>
                </a-row>
            </a-row>
        </div>


        <a-modal v-model="commissionInfoVisible" title="分单详情" id="commission-info-modal"
                 @ok="commissionInfoShow">
            <a-row class="commission-info-item">
                <a-col class="info-item-label info-item-col" :span="10">分单人姓名</a-col>
                <a-col class="info-item-value info-item-col" :span="14">李爱聊</a-col>
            </a-row>
            <a-row class=" commission-info-item">
                <a-col class="info-item-label info-item-col" :span="10">分单金额</a-col>
                <a-col class="info-item-value info-item-col" :span="14">10000.00</a-col>
            </a-row>
            <a-row class=" commission-info-item">
                <a-col class="info-item-label info-item-col" :span="10">金额比例</a-col>
                <a-col class="info-item-value info-item-col" :span="14">50%</a-col>
            </a-row>
            <a-row class=" commission-info-item">
                <a-col class="info-item-label info-item-col" :span="10">分单人所属中心</a-col>
                <a-col class="info-item-value info-item-col" :span="14">深圳印力中心</a-col>
            </a-row>
        </a-modal>
	</div>
</template>
<script>
	export default {
		name: 'ContractDetail',
		data() {
			return {
                commissionInfoVisible:false,
            }
		},
		created() {
		},
		mounted() {

		},
		methods: {
            commissionInfoShow(){
                this.commissionInfoVisible=true;
            }
		}
	}
</script>
<style scoped lang="less">
	.contract-detail-page {
        margin-bottom: 120px;
        .content-item-row{
            margin-top: 30px;
            .vertical-line{
                border-left: #108ee9  solid 4px;
                padding-left: 10px;
            }
            .student-profile-content{
                width: 600px;
                border:solid 1px lightgrey;
                padding: 20px;
                margin-left:20px ;
                margin-top:20px ;
                margin-bottom:20px ;
            }
            .content-row{
                font-size: 16px;
                margin-top: 20px;
                margin-left: 20px;
                line-height: 40px;
            }
        }


	}


    #commission-info-modal{
        .commission-info-item{
            padding-left: 40px;
            text-align: left;
            height:48px;
        }
    }




</style>
