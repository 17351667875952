<template>
        <div>
            <div style="background-color: white;display: flex;flex-direction: column;padding: 20px">
                <div style="display: flex;justify-content: space-between;padding: 10px;">
                    <div style="display: flex;align-items: center;">
                        <div >   <a-avatar :size="64" icon="user" /></div>
                        <div style="display: flex;flex-direction: column;margin-left: 10px;padding: 5px;">
                            <div style="font-size: 25px;">王乐</div>
                            <div style="display: flex;align-items: center">
                                <a-button size="small">续费用户</a-button>
                                <a-button size="small" style="margin-left: 5px;">多子女</a-button>
                                <span style="margin-left: 5px;">编辑</span>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div>
                            <a-button>办理试听</a-button>
                        </div>
                        <div>
                            <a-button>创建合同</a-button>
                        </div>
                        <div>
                        <a-popover placement="bottom">
                            <template slot="content">
                                <p>更换负责人</p>
                                <p>转移到公共池</p>
                                <p>联系客户</p>
                                <p>查看操作记录</p>
                                <p>删除</p>
                            </template>
                            <a-button>更多</a-button>
                        </a-popover>
                        </div>
                    </div>
                </div>
                <div style="height: 100px;display: flex;justify-content: space-between;padding: 20px">

                    <div>
                        <div>所属中心</div>
                        <div>深圳百花中心</div>
                    </div>
                    <div>
                        <div>意向度</div>
                        <div>强</div>
                    </div>
                    <div>
                        <div>生日</div>
                        <div>2015-05-18</div>
                    </div>
                    <div>
                        <div>状态</div>
                        <div>已体检</div>
                    </div>
                    <div>
                        <div>渠道来源</div>
                        <div>网络>大众点评</div>
                    </div>

                </div>
            </div>
            <div style="background-color: white;margin-top: 10px;min-height: 60vh">
                <div style="position: relative;z-index: 99;">
                    <div style="position: absolute;z-index: 99;right:0px;top: 400px;display: flex;">
                            <div class="follow-item" style="cursor: pointer" @click="show">
                                <a-icon type="left" />
                            </div>
                        <div class="other" v-show="visible">
                            <div class="show-ul">
                                <div class="show-li">服务团队</div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name">顾问</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name">市场</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name">TMK</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name">客服</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="show-ul">
                                <div class="show-li">重要信息</div>

                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">姓名</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">昵称</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">生日</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">来源</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">状态</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">练习方式</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">标签</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">备注</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">公众号</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">小程序</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">创建时间</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>
                            <div class="div-ul-li">
                                <div style="display: flex;padding: 10px;"><label class="div-ul-li-name2">最后跟进时间</label><div>王丽</div><div style="margin-left: 10px">更换</div></div>
                            </div>

                        </div>
                    </div>

                </div>
                <Table :config="config" :changeTab="changeTab">
                    <div slot="form">
                        <component v-bind:is="config.formConfig.component"></component>
                    </div>
                </Table>
            </div>
        </div>
</template>

<script>
import Table from "../../components/Customer/Table"
import Dynamic from "./Dynamic";
import FollowRecord from "./FollowRecord";
import Information from "./Information";
import ContactDetail from "../CustomContract/ContactDetail";
import ClassInfo from "./ClassInfo";
import Other from "./Other";
import Order from "./Order";
export default {
    components: {
        Table,
        Dynamic,
        FollowRecord,
        Information,
        ContactDetail,
        ClassInfo,
        Other,
        Order,
    },
    props:{

    },
    data() {
        return {
            visible: false,
            top: 10,
            config: {
                tabSize:'large',
                tabsConfig:['跟踪记录','详情信息','合同','上课信息','产品订单','动态','其他'],//指定tab切换标签
                searchConfig: {
                    searchFields:[],
                    buttonFields:[]
                },
                tableConfig:{
                    columns:[],
                    data:[],
                },
                formConfig:{
                    component:'',
                }
            },
        };
    },
    mounted() {
        let that = this;
        that.changeTab(0)
    },
    methods: {
        show(){
            this.visible = !this.visible;
        },
        onClose() {
            this.visible = false;
        },
        changeTab(key){
            let that = this;
            console.log('点击'+that.config.tabsConfig[key]+'模块'+'+索引:'+key)
            switch (key) {//方法名
                case 0:
                    that.config.tableConfig.data = [];
                    that.config.tableConfig.columns = [];
                    that.config.searchConfig.searchFields=[]
                    that.config.searchConfig.buttonFields=[]
                    that.config.formConfig.component = 'FollowRecord';
                    break;
                case 1:
                    that.config.tableConfig.data = [];
                    that.config.tableConfig.columns = [];
                    that.config.searchConfig.searchFields=[]
                    that.config.searchConfig.buttonFields=[]
                    that.config.formConfig.component = 'Information';
                    break;
                case 2:
                    that.config.tableConfig.data = [];
                    that.config.tableConfig.columns = [];
                    that.config.searchConfig.searchFields=[]
                    that.config.searchConfig.buttonFields=[]
                    that.config.formConfig.component = 'ContactDetail';
                    break;
                case 3:
                    that.config.tableConfig.data = [];
                    that.config.tableConfig.columns = [];
                    that.config.searchConfig.searchFields=[]
                    that.config.searchConfig.buttonFields=[]
                    that.config.formConfig.component = 'ClassInfo';
                    break;
                case 4:
                    that.config.tableConfig.data = [];
                    that.config.tableConfig.columns = [];
                    that.config.searchConfig.searchFields=[]
                    that.config.searchConfig.buttonFields=[]
                    that.config.formConfig.component = 'Order';
                    break;
                case 5:
                    that.config.tableConfig.data = [];
                    that.config.tableConfig.columns = [];
                    that.config.searchConfig.searchFields=[]
                    that.config.searchConfig.buttonFields=[]
                    that.config.formConfig.component = 'Dynamic';
                    break;
                    case 6:
                        that.config.tableConfig.data = [];
                        that.config.tableConfig.columns = [];
                        that.config.searchConfig.searchFields=[]
                        that.config.searchConfig.buttonFields=[]
                        that.config.formConfig.component = 'Other';

                        break;
            }
        },

    },
}
</script>

<style scoped>
    .show-ul{
        padding: 30px 30px 10px 30px;
    }
    .show-li{
        border-width: 0px 0px 0px 5px;

        border-style: solid;
        border-color: rgb(131, 122, 122) rgb(131, 122, 122) rgb(131, 122, 122) #00a1ff;
        border-image: initial; padding: 5px;
    }
    .div-ul-li{
        display: flex;
        margin-left: 35px;
        margin-right: 35px;
        justify-content: space-between;
    }

    .div-ul-li-name{
        padding-right: 30px;
    }
    .div-ul-li-name2{
        padding-right: 40px;
    }
    .other{
        width: 450px;
        min-height:90vh;
        position: relative ;
        border: 1px solid rgb(232, 232, 232);
        box-shadow: -2px 2px 5px -3px;
        top: -360px;
        opacity: 1;
        background-color: white;
        transition: none;
        -webkit-animation: antdDrawerFadeIn .3s cubic-bezier(.7,.3,.1,1);
        animation: antdDrawerFadeIn .3s cubic-bezier(.7,.3,.1,1);

    }
    .follow-item{
        height: 90px;
        color: #fff;
        background-color: #7ea6ff;
        border-color: #70bbff;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        text-align: center;
        display: flex;
        align-items: center;
    }
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    margin-right:0px;
    background-image: linear-gradient(180deg, #dddddd, #dfdcdc);
    border-radius:0px;
    border: 0px solid #e8e8e8;
    color: #2c3e50;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    border-bottom:0px;
    background-image: linear-gradient(180deg, #ffffff, #ffffff);
    color: #2c3e50;
}
/deep/.ant-tabs-nav-scroll {
    background-image: linear-gradient(180deg, #dddddd, #dfdcdc);
    color: #2c3e50;
    }
</style>